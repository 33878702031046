import BaseService from "@core/services/baseService";

export default class TaskService extends BaseService{
    createTask(...args){
        return this.axiosIns.post(this.formConfig.taskEndpoint,...args)
    }
    updateTask(...args){
        return this.axiosIns.post(this.formConfig.taskEndpoint+"/edit/"+args[0].get('id'),...args)
    }
    getTask(...args){
        return this.axiosIns.get(this.formConfig.taskEndpoint+"/"+args[0].id,...args)
    }
    getRecentTaskData(...args){
        return this.axiosIns.get(this.formConfig.taskDashboardEndpoint,...args)
    }
    changeTaskStatus(...args){
        return this.axiosIns.post(this.formConfig.taskEndpoint+"/status/"+args[0].id,...args)
    }
    getAllTasks(...args){
        return this.axiosIns.get(this.formConfig.taskEndpoint,...args)
    }
    getAllUserTasks(...args){
        return this.axiosIns.post(this.formConfig.userTaskEndpoint,...args)
    }
    getAllReviewTasks(...args){
        return this.axiosIns.post(this.formConfig.taskReviewEndpoint,...args)
    }
}