<template>
  <section id="dashboard-practice">
    <b-overlay
        :show="!pageLoaded"
    >

      <b-card
          v-if="pageLoaded && practiceId === 0"
          no-body
          class="mb-0"
      >

        <div class="m-2 pb-4 text-center">
          <img src="@/assets/images/illustration/marketing.svg">
          <h3 class="mt-3">You don't have a practice yet. Would you like to create one?</h3>
          <b-button href="/practice/edit">Create Practice</b-button>
        </div>
      </b-card>

      <b-card
          v-if="pageLoaded && practiceId && !practiceProfileCompleted"
          no-body
          class="mb-0"
      >

        <div class="m-2 pb-4 text-center">
          <img src="@/assets/images/illustration/marketing.svg">
          <h3 class="mt-3">Your practice profile has not been completed. Please complete that first.</h3>
          <b-button href="/practice/edit">Complete Practice Profile</b-button>
        </div>
      </b-card>

    <b-row class="match-height" v-if="practiceProfileCompleted && practiceId !== 0">
      <b-col
          lg="6"
          md="12"
      >
<!--        <analytics-congratulation :data="data.congratulations" />-->
      </b-col>
      <b-col
          lg="3"
          sm="6"
      >
<!--        <statistic-card-with-area-chart-->
<!--            v-if="data.subscribersGained"-->
<!--            icon="UsersIcon"-->
<!--            :statistic="kFormatter(data.subscribersGained.analyticsData.subscribers)"-->
<!--            statistic-title="Subscribers Gained"-->
<!--            :chart-data="data.subscribersGained.series"-->
<!--        />-->
      </b-col>
      <b-col
          lg="3"
          sm="6"
      >
<!--        <statistic-card-with-area-chart-->
<!--            v-if="data.ordersRecevied"-->
<!--            icon="PackageIcon"-->
<!--            color="warning"-->
<!--            :statistic="kFormatter(data.ordersRecevied.analyticsData.orders)"-->
<!--            statistic-title="Orders Received"-->
<!--            :chart-data="data.ordersRecevied.series"-->
<!--        />-->
      </b-col>
    </b-row>

    <b-row class="match-height" v-if="practiceProfileCompleted && practiceId !== 0">
      <b-col lg="6">
        <practice-health :data="practiceHealth" />
      </b-col>
      <b-col lg="6">
        <client-health :data="clientHealth" />
      </b-col>
    </b-row>

    <b-row class="match-height" v-if="practiceProfileCompleted && practiceId !== 0">
      <b-col lg="6">
        <services-health :data="servicesHealth" />
      </b-col>
      <b-col lg="6">
        <compliance-health :data="complianceHealth" />
      </b-col>
    </b-row>

    <b-row class="match-height" v-if="practiceProfileCompleted && practiceId !== 0">
      <b-col lg="6">
        <supervision-days-remaining :data="supervisionDaysRemaining" />
      </b-col>
      <b-col lg="6">
        <practice-tasks />
      </b-col>
    </b-row>

    <b-row v-if="practiceProfileCompleted && practiceId !== 0">
      <b-col cols="12">
        <client-list />
      </b-col>
    </b-row>
    </b-overlay>
  </section>
</template>

<script>
import { BRow, BCol, BButton, BOverlay, BCard } from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
// import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
// import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'
import PracticeHealth from './PracticeHealth.vue'
import ClientHealth from './ClientHealth.vue'
import ServicesHealth from './ServicesHealth.vue'
import ComplianceHealth from './ComplianceHealth.vue'
import SupervisionDaysRemaining from './SupervisionDaysRemaining.vue'
// import AnalyticsTimeline from './AnalyticsTimeline.vue'
// import AnalyticsSalesRadarChart from './AnalyticsSalesRadarChart.vue'
import practiceService from '@core/services/practice/usePractice'
import PracticeTasks from './PracticeTasks.vue'
import ClientList from '../../pages/client/ClientList.vue'
import riskService from "@core/services/risk/useRisk";
import {$themeConfig} from "@themeConfig";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BOverlay,
    BCard,
    ClientList,
    PracticeHealth,
    PracticeTasks,
    ClientHealth,
    ServicesHealth,
    ComplianceHealth,
    SupervisionDaysRemaining,
  },
  mounted() {
    let userData = JSON.parse(localStorage.getItem('userData'));

    if ( userData.practiceId ){
      this.practiceId = userData.practiceId;
    }

    if ( this.practiceId ){
      this.getData();
    }
  },
  data() {
    return {
      practiceId: 0,
      data: {},
      pageLoaded: false,
      practiceProfileCompleted: false,
      practiceHealth: {
        practiceHealthRadialBar: {
          series: [],
        },
      },
      clientHealth: {
        clients: {
          lowRisk: 0,
          medRisk: 0,
          highRisk: 0,
          veryHighRisk: 0,
        },
        clientHealthRadialBar: {
          series: [],
        },
      },
      servicesHealth: {
        servicesHealthRadialBar: {
          series: [],
        },
      },
      complianceHealth: {
        complianceRadialBar: {
          series: [],
        },
      },
      supervisionDaysRemaining: {
        expirationDate: '31/12/22',
        daysRemaining: '45',
        series: [13],
      }
    }
  },
  methods: {
    getData(){
      practiceService.getPracticeData({
        'id': this.practiceId
      }).then(response => {
          this.practiceHealth.practiceHealthRadialBar.series.push(response.data.totalPracticeRiskPercentage);
          this.clientHealth.clientHealthRadialBar.series.push(response.data.totalClientRiskPercentage);
          this.servicesHealth.servicesHealthRadialBar.series.push(response.data.totalAMLRiskPercentage);
          this.complianceHealth.complianceRadialBar.series.push(response.data.totalComplianceRiskPercentage);
          this.practiceProfileCompleted = response.data.practiceProfileCompleted;
          this.supervisionDaysRemaining.expirationDate = response.data.supervisionExpirationDate;
          this.supervisionDaysRemaining.daysRemaining = response.data.supervisionDaysRemaining;
          this.supervisionDaysRemaining.series = [response.data.supervisionDaysPercent];
          this.clientHealth.clients.lowRisk = response.data.clients.lowRisk;
          this.clientHealth.clients.medRisk = response.data.clients.medRisk;
          this.clientHealth.clients.highRisk = response.data.clients.highRisk;
          this.clientHealth.clients.veryHighRisk = response.data.clients.veryHighRisk;

          this.pageLoaded = true;
      }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
    }
  },
}
</script>
