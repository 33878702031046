import BaseService from "@core/services/baseService";

export default class PracticeService extends BaseService
{
    createPractice(...args){
        return this.axiosIns.post(this.formConfig.practiceEndpoint,...args)
    }
    updatePractice(...args){
        return this.axiosIns.post(this.formConfig.practiceEndpoint+"/edit/"+args[0].get('id'),...args)
    }
    getPractice(...args){
        return this.axiosIns.get(this.formConfig.practiceEndpoint+"/"+args[0].id,...args)
    }
    download(...args){
        return this.axiosIns.get(this.formConfig.practiceEndpoint+"/download/"+args[0].id,...args)
    }
    getPracticeData(...args){
        return this.axiosIns.get(this.formConfig.practiceDashboardEndpoint+"/"+args[0].id,...args)
    }
    getAllPractices(...args){
        return this.axiosIns.get(this.formConfig.practiceEndpoint,...args)
    }
    getPracticeDownload(...args){
        return this.axiosIns.get(this.formConfig.practiceDownloadEndpoint,...args)
    }
}