<template>
    <b-card no-body>
        <b-card-header class="pb-1">
            <b-card-title>Your Tasks</b-card-title>
        </b-card-header>
        <b-card-body>
            <div class="task-list-wrap">
                <div v-for="task in tasks" class="task-wrap" :key="task.id" @click="handleTaskClick(task)">
                    <b-form-checkbox class="name-wrap" :checked="task.completed" @click.native.stop @change="handleTaskClick(task)" :disabled="task.completed">
                        <span class="task-info" :class="{'completed': task.reviewed}">{{ task.name }}</span>
                    </b-form-checkbox>
                    <small v-if="task.due_date_format" class="due-date">{{ task.due_date_format }}</small>
                </div>
            </div>
        </b-card-body>
    </b-card>
</template>
<script>
    import {BCard,BCardHeader,BCardTitle,BCardBody,BRow,BCol,BCardText,BFormCheckbox} from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'
    import {$themeColors, $themeConfig} from '@themeConfig'
    import taskService from '@core/services/task/useTask'

    export default {
        components: {
            VueApexCharts,
            BCard,
            BCardHeader,
            BCardTitle,
            BCardText,
            BCardBody,
            BRow,
            BCol,
            BFormCheckbox
        },
        data(){
            return {
                tasks: []
            }
        },
        methods: {
            handleTaskClick(task){
                if(!task.completed){
                    taskService.changeTaskStatus({id: task.id,completed: !task.completed}).then(response => {
                        task.completed = !task.completed;
                    });
                }
            },
            getRecentTasks: function(){
                taskService.getRecentTaskData().then(response => {
                    this.tasks = response.data;
                }).catch(error => {
                    if(error.response.status == 403){
                      window.location = $themeConfig.app.suspendedAccountRedirect;
                    }
                });
            },
        },
        mounted(){
            this.getRecentTasks();
        }
    }
</script>
<style>
    .task-list-wrap{display:flex;flex-direction:column;flex:1;}
    .task-list-wrap .task-wrap{background:#fff;border-bottom:1px solid #ebe9f1;cursor:pointer;padding:12px 0;display:flex;align-items:flex-start;column-gap:10px;justify-content:space-between;}
    .task-list-wrap .task-wrap:last-child{border-bottom:none;}
    .task-list-wrap .task-wrap .name-wrap{display:flex;flex-direction:column;row-gap:3px;}
    .task-list-wrap .task-wrap .name-wrap .task-info{font-size:12px;line-height:18px;-webkit-line-clamp:2;overflow:hidden;display:-webkit-box;-webkit-box-orient:vertical;}
    .task-list-wrap .task-wrap .name-wrap .task-info.completed{text-decoration:line-through;}
    .task-list-wrap .task-wrap .due-date{font-size:10px;line-height:18px;color:#ffa500;flex:0 0 60px;}
</style>
<style lang="scss">
    @import '@core/scss/vue/pages/ui-feather.scss';
</style>