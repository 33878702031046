<template>
    <div class="bi-cust-model" v-show="show">
        <div class="bi-cust-model-back" @click="close"></div>
        <div class="bi-cust-model-inner large">
            <div class="bi-cust-model-header">
                <h4>Import</h4>
                <button @click="close" class="btn">
                    <svg width="30" height="30" viewBox="0 0 43 43" fill="none">
                        <rect x="11.5154" y="9.69751" width="30" height="3.42857" transform="rotate(45 11.5154 9.69751)" fill="#828282"/>
                        <rect x="33.335" y="12.1218" width="30" height="4.28571" transform="rotate(135 33.335 12.1218)" fill="#828282"/>
                    </svg>
                </button>
            </div>
            <div class="bi-cust-model-body">
                <form @submit.prevent="createUpdateHandler">
                    <div class="cust-form-group mb0">
                        <div class="bi-cust-upload-wrap">
                            <img src="/import.png" alt="Import" width="100"/>
                            <div class="title">Browse the File to import.</div>
                            <div class="or" style="margin-bottom:15px;">Make sure you are uploading the downloaded excel sheet.</div>
                            <button type="button" class="bi-cust-btn" @click="browseFile">Browse files</button>
                            <input type="file" ref="import" @change="uploadImportFile" accept=".csv"/>
                        </div>
                    </div>
                </form>
                <a href="/sample.csv" class="download" download>Download Sample</a>
            </div>
        </div>
    </div>
</template>
<script>
    import clientService from "@core/services/client/useClient";
    import ToastificationContent from "@core/components/toastification/ToastificationContent";
    import {$themeConfig} from "@themeConfig";
    export default {
        props: ["show"],
        data(){
            return {
                errors: [],
                records: []
            }
        },
        methods: {
            close(){
                this.$emit("close");
            },
            browseFile: function(){
                this.$refs.import.click();
            },
            uploadImportFile: function(){
                if(this.$refs.import){
                    var formData = new FormData();
                    formData.append('import',this.$refs.import.files[0]);
                    clientService.importClient(formData).then(response => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Success!`,
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: `You have successfully import client list`,
                            }
                        });
                        if(response.status == 200){
                            this.$emit("close",true);
                        }
                    }).catch(error => {
                        if(error.response.status == 403){
                          window.location = $themeConfig.app.suspendedAccountRedirect;
                        }
                    });
                }
            }
        },
        created(){
            const closeOnEscape = (e) => {
                if(e.key === "Escape" && this.show){
                    this.close();
                }
            };
            document.addEventListener("keydown",closeOnEscape);
            this.$once("hook:destroyed",() => {
                document.removeEventListener("keydown",closeOnEscape);
            });
        }
    };
</script>
<style lang="scss" scoped>
.bi-cust-model{position:fixed;top:0;bottom:0;left:0;right:0;z-index:9999;display:flex;justify-content:flex-end;background:#0000002b;}
.bi-cust-model .bi-cust-model-back{position:absolute;left:0;top:0;right:0;bottom:0;}
.bi-cust-model .bi-cust-model-inner{background:#fff;z-index:99999;max-width:500px;width:100%;}
.bi-cust-model .bi-cust-model-header{display:flex;flex-direction:row;justify-content:space-between;column-gap:20px;align-items:center;padding:19px 30px;background:#e8ecff;}
.bi-cust-model .bi-cust-model-header h4{margin:0;font-size:24px;line-height:28px;font-weight:600;color:#06243f;text-transform:uppercase;}
.bi-cust-model .bi-cust-model-header .btn{padding:0;margin:0;}
.bi-cust-model .bi-cust-model-body{padding:25px;overflow-x:hidden;overflow-y:auto;height:calc(100vh - 72px);text-align:center;}
.bi-cust-model .bi-cust-model-body::-webkit-scrollbar{width:5px;}
.bi-cust-model .bi-cust-model-body::-webkit-scrollbar-track{box-shadow:inset 0 0 5px grey;}
.bi-cust-model .bi-cust-model-body::-webkit-scrollbar-thumb{background:grey;border-radius:10px;}
.bi-cust-model .bi-cust-model-body::-webkit-scrollbar-thumb:hover{background:grey;}
.bi-cust-model .bi-cust-model-body .cust-form-group{display:flex;flex-direction:column;margin-bottom:20px;}
.bi-cust-model .bi-cust-model-body .cust-form-group.mb0{margin-bottom:0;}
.bi-cust-model .bi-cust-model-body .cust-form-group .download{margin:5px auto;display:inline-flex;font-size:14px;}
.bi-cust-upload-wrap{background:#fff;border:2px dashed #0176a5;box-sizing:border-box;border-radius:10px;display:flex;flex-direction:column;align-items:center;justify-content:center;padding:30px;row-gap:8px;}
.bi-cust-upload-wrap .title,
.bi-cust-upload-wrap .or{font-size:16px;line-height:26px;color:#6c757d;text-align:center;}
.bi-cust-upload-wrap .filename{font-weight:700;font-size:20px;line-height:1;}
.bi-cust-upload-wrap input{height:0;width:0;opacity:0;display:none;}
.bi-cust-btn{background:#2C448C;border:none;color:#fff;border-radius:5px;padding:10px 20px;font-weight:500;font-size:16px;line-height:20px;box-shadow:none;cursor:pointer;text-decoration:none;}
.bi-cust-btn:hover{background:#000;color:#fff !important;text-decoration:none;}
</style>